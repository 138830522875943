import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import RefreshIcon from "@material-ui/icons/Refresh";

export const ButtonCache = ({ getPublicaciones }) => {
  const updatedAt = new Date(getPublicaciones.dataUpdatedAt).toLocaleString();

  return (
    <Grid
      container
      style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20 }}
    >
      <Grid item xs={3}>
        <Button
          startIcon={<RefreshIcon />}
          variant="outlined"
          color={"primary"}
          style={{ fontWeight: "bold" }}
          onClick={() => getPublicaciones.refetch()}
          disabled={getPublicaciones.isFetching}
        >
          Refrescar
        </Button>
      </Grid>
      <Grid item xs={9} alignContent="flex-start">
        <Typography variant="subtitle1" color="textPrimary">
          Última vez refrescado: {updatedAt}
        </Typography>
      </Grid>
    </Grid>
  );
};
