import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import BodyA4 from "./Components/BodyA4";
export default function PresupuestoA4({ data, config, tipo }) {
  return (
    <Document title={"Presupuesto"}>
      <Page
        style={{
          // paddingBottom: data.detalles.length > 17 ? 150 : 200,
          paddingBottom:
            tipo === "presupuestoA4"
              ? data.detalles.length > 17
                ? 150
                : 200
              : 10,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {tipo === "presupuestoA4" ? (
          <BodyA4 data={data} tipo={tipo} config={config} />
        ) : !config.es_duplicado ? (
          <BodyA4 data={data} tipo={tipo} config={config} />
        ) : (
          [0, 1].map(() => <BodyA4 data={data} tipo={tipo} config={config} />)
        )}
        <Text
          style={
            tipo === "presupuestoA4"
              ? styles.pageNumber
              : { fontSize: 10, textAlign: "center", color: "grey" }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
