import React from "react";
import { Button } from "@material-ui/core";

export default function ButtonGeneral({
  disabled,
  click,
  message,
  style,
  fullwidth,
  size,
}) {
  let newStyle = style ? { ...style, outline: "none" } : { outline: "none" };

  return (
    <Button
      style={newStyle}
      fullWidth={fullwidth || false}
      variant="contained"
      color="primary"
      onClick={click}
      disabled={disabled}
      size={size || "medium"}
    >
      {message}
    </Button>
  );
}
