import React from "react";
import { Button } from "@material-ui/core";

export default function ButtonCancelar({
  click,
  message,
  disabled,
  fullwidth,
  size,
  style,
}) {
  let newStyle = style
    ? { ...style, outline: "none", backgroundColor: "#c4183c", color: "white" }
    : { outline: "none", backgroundColor: "#c4183c", color: "white" };
  return (
    <Button
      variant="contained"
      fullWidth={fullwidth || false}
      onClick={click}
      disabled={disabled ? disabled : false}
      style={newStyle}
      size={size || "medium"}
    >
      {message}
    </Button>
  );
}
