import React, { useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { useDispatch } from "react-redux";
import {
  setArticuloVinculacion,
  setViewVinculacion,
} from "../../../../../Redux/Actions/actionsVinculacionesMELI";
import { Alert } from "@material-ui/lab";
import { tipoPublicaciones } from "../utils";

export default function FiltroPublicaciones({
  search,
  setSearch,
  filters,
  setFilters,
  articuloVinculacion,
}) {
  const dispatch = useDispatch();
  const handleSearch = ({ target }) => {
    const { value } = target;
    setSearch(value);
  };

  const handleDelete = () => {
    dispatch(setArticuloVinculacion(null, false));
  };

  useEffect(() => {
    dispatch(setViewVinculacion(true));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "estado") {
      localStorage.setItem("filtroEstadoPubl", value);
    }
    if (name === "publicacion") {
      localStorage.setItem("filtroEstadoPubl", "todas");
      setFilters((prev) => ({
        ...prev,
        tipo: "todas",
        estado: "todas",
      }));
    }
  };

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 10 }}>
        {articuloVinculacion && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Alert
              icon={false}
              severity="info"
              variant="filled"
              onClose={handleDelete}
              style={{ backgroundColor: "#3f51b5" }}
            >
              <strong>{`Seleccioná una publicación para vincularla al artículo: C: ${
                articuloVinculacion.codProveedor || "N/A"
              } - D: ${articuloVinculacion.descripcionProveedor} - $${
                articuloVinculacion.precios.venta || "0.00"
              } - P: ${articuloVinculacion.alias_proveedor || "N/A"}`}</strong>
            </Alert>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            type={"text"}
            variant="outlined"
            fullWidth
            size="small"
            label="Buscar por nombre de publicación"
            placeholder="Buscar por nombre de publicación"
            value={search || ""}
            onChange={(event) => handleSearch(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id={"md-lbl"}>Publicación</InputLabel>
            <Select
              labelId="md-lbl"
              id="select-md"
              value={filters.publicacion}
              onChange={handleChange}
              label="Publicación"
              name="publicacion"
            >
              <MenuItem value={0}>Todas</MenuItem>
              <MenuItem value={1}>Vinculadas</MenuItem>
              <MenuItem value={2}>No vinculadas</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id={"md-lbl"}>Tipo</InputLabel>
            <Select
              labelId="md-lbl"
              id="select-md"
              value={filters.tipo}
              onChange={handleChange}
              label="Tipo"
              name="tipo"
              disabled={filters.publicacion === 1 ? true : false}
            >
              <MenuItem value="todas">Todas</MenuItem>
              {tipoPublicaciones().map((tipo, index) => {
                return (
                  <MenuItem value={tipo.id} key={index}>
                    {tipo.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id={"md-lbl"}>Estado</InputLabel>
            <Select
              labelId="md-lbl"
              id="select-md"
              value={filters.estado}
              onChange={handleChange}
              label="Estado"
              name="estado"
              disabled={filters.publicacion === 1 ? true : false}
            >
              <MenuItem value={"todas"}>Todas</MenuItem>
              <MenuItem value={1}>Activas</MenuItem>
              <MenuItem value={0}>Pausadas</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
